import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/layout"
import WebriqForm from "../../components/form/form"

import Fb from "../../images/contact/facebook.png"
import Linkedin from "../../images/contact/linkedin.png"
import Twitter from "../../images/contact/twitter.png"
import Instagram from "../../images/contact/instagram.png"

const OncologyContactUs = () => {
  return (
    <Layout page="oncology/contact-us">
      <Container fluid className="contact-page">
        <Container>
          <h1 className="text-center contact-flair mb-5 text-blue">
            CONTACT US
          </h1>

          <Row style={{ paddingTop: "50px" }} className="gy-5">
            <Col lg="6" className="mb-5">
              {" "}
              <WebriqForm
                name="Candidates Looking for a Job"
                className="webriq-form"
                form_id="68766da5-0c50-40c9-938a-859f92097230"
                data_thankyou_url="/thank-you"
              >
                <h4 className="mb-4">Candidates Looking for a Job</h4>
                <div className="form-group mt-0">
                  <input
                    name="Email Address"
                    className="form-control"
                    id="email"
                    type="email"
                  />
                  <label htmlFor="phone">Your Email Address</label>
                </div>

                <Row>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Firstname"
                        className="form-control"
                        type="text"
                        id="firstName"
                        required=""
                      />

                      <label htmlFor="firstName">Firstname</label>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Lastname"
                        className="form-control"
                        type="text"
                        id="lastname"
                        required=""
                      />

                      <label htmlFor="firstName">Lastname</label>
                    </div>
                  </Col>
                </Row>

                <div className="form-group mt-3">
                  <input
                    name="Phone Number"
                    className="form-control"
                    id="phone"
                    type="phone"
                  />
                  {/* <p className="opt-in-note ml-1" style={{ fontSize: "14px" }}>
                    You will not receive any SMS messages from us unless you
                    verbally opt-in.
                  </p> */}
                  <label htmlFor="phone">Phone Number</label>
                </div>
                <div className="form-group mt-3">
                  <input
                    name="Job Title"
                    className="form-control"
                    id="phone"
                    type="text"
                  />
                  <label htmlFor="job">Job Title</label>
                </div>
                <div className="form-group mt-3">
                  <h6>Attach Resume</h6>
                  <input type="file" id="myfile" name="Attach Resume"></input>
                </div>
                <div className="form-group mt-4">
                  <textarea
                    name="Message"
                    className="form-control h-auto"
                    id="message"
                    required=""
                    rows="6"
                    cols="50"
                  />
                  <label htmlFor="message">Message</label>
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mt-3 mt-0">
                  <button
                    className="common-btn m-0"
                    type="submit"
                    style={{ border: "none" }}
                  >
                    SUBMIT
                  </button>
                </div>
                <div className="form-group mt-4 smsCheckbox">
                  <input
                    type="checkbox"
                    id="SMSMessage"
                    name="SMS Message from PeerSource"
                    value="Yes"
                  />
                  <label htmlFor="SMSMessage">
                    By checking this box, you agree to receive SMS messages from
                    PeerSource. You can reply STOP to opt out at any time.
                    Standard SMS fees and Privacy Policy may apply. Note that
                    checking this box is not required to submit a message.
                  </label>
                </div>
              </WebriqForm>
            </Col>
            <Col lg="6" className="mb-5">
              {" "}
              <WebriqForm
                name="Facilities Needing Staffing Help"
                className="webriq-form"
                form_id="e2b1763c-5ceb-4c99-a56e-ee8ed463844d"
                data_thankyou_url="/thank-you"
              >
                <h4 className="mb-4">Facilities Needing Staffing Help</h4>
                <div className="form-group mt-0">
                  <input
                    name="Email Address"
                    className="form-control"
                    id="email"
                    type="email"
                  />
                  <label htmlFor="phone">Your Email Address</label>
                </div>

                <Row>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Firstname"
                        className="form-control"
                        type="text"
                        id="firstName"
                        required=""
                      />

                      <label htmlFor="firstName">Firstname</label>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Lastname"
                        className="form-control"
                        type="text"
                        id="lastname"
                        required=""
                      />

                      <label htmlFor="firstName">Lastname</label>
                    </div>
                  </Col>
                </Row>

                <div className="form-group mt-3">
                  <input
                    name="Phone Number"
                    className="form-control"
                    id="phone"
                    type="phone"
                  />
                  {/* <p className="opt-in-note ml-1" style={{ fontSize: "14px" }}>
                    You will not receive any SMS messages from us unless you
                    verbally opt-in.
                  </p> */}
                  <label htmlFor="phone">Phone Number</label>
                </div>
                <div className="form-group mt-3">
                  <input
                    name="Company Name"
                    className="form-control"
                    id="phone"
                    type="text"
                  />
                  <label htmlFor="phone">Company Name</label>
                </div>
                <div className="form-group mt-4">
                  <input
                    name="Job Title"
                    className="form-control"
                    id="phone"
                    type="text"
                  />
                  <label htmlFor="phone">Job Title</label>
                </div>
                <div className="form-group mt-4">
                  <textarea
                    name="Message"
                    className="form-control h-auto"
                    id="message"
                    required=""
                    rows="6"
                    cols="50"
                  />
                  <label htmlFor="message">Message</label>
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mt-3 mt-0">
                  <button
                    className="common-btn m-0"
                    type="submit"
                    style={{ border: "none" }}
                  >
                    SUBMIT
                  </button>
                </div>
              </WebriqForm>
            </Col>
            <Col lg="6" className="mb-5">
              <div className="text-right">
                <p style={{ fontWeight: "900", color: "#176588" }}>
                  Primary office location is in the Denver, CO area at:{" "}
                </p>
                <p>
                  10303 E. Dry Creek Road, Suite 110 <br /> Englewood, CO 80112
                </p>
                <br />
                <a href="tel:9414213330">P: 941-421-3330</a>
                <br />
                <a href="fax:3033283531">F: 303-328-3531</a>
              </div>
            </Col>
            <Col lg="6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3074.9780304842725!2d-104.87176022352344!3d39.58264550598117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c85dd5a00712b%3A0x6f61d5ed7f792b7e!2s10303%20E%20Dry%20Creek%20Rd%20%23%20110%2C%20Englewood%2C%20CO%2080112%2C%20USA!5e0!3m2!1sen!2sph!4v1729654971510!5m2!1sen!2sph"
                width="100%"
                height="250"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                title="Map"
              ></iframe>
              <a
                href="https://goo.gl/maps/FB9aXQUpV8kZ1tLx9"
                className="common-btn text-left"
                style={{ border: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                VIEW LARGER MAP
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="call-us-cta">
        <Container>
          <Row className="align-items-center">
            <Col xl={7} className="text-center text-xl-left">
              <h2 className="new-h2 cta-title text-white">
                Give us a call today to find out how{" "}
                <span style={{ color: "#0088A0" }}>PeerSource</span> can help
                YOU.
              </h2>
              <p
                style={{ marginTop: "30px" }}
                className="text-white lead font-italic"
              >
                We work across the entire US and staff in all 50 states.
              </p>
            </Col>
            <Col xl={5} className="text-center text-xl-right my-5">
              <a
                href="tel:941-421-3330"
                target="_blank"
                rel="noreferrer"
                className="new-btn huge"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <i className="fa fa-phone lead" />
                <span className="ml-2">941-421-3330</span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default OncologyContactUs
